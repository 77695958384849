import React from 'react';
import Direksi from './Card/Direksi';
import dirut from '../assets/images/dirut.png';
import direktur from '../assets/images/direktur.png';
import komut from '../assets/images/komut.png';
import komisaris from '../assets/images/komisaris.png';
import komisaris2 from '../assets/images/kom2.png';
import Animate from './Animate';

const Jajaran = () => {
  return (
    <div className='header bg-white p-10 text-primary'>
      <h1 className='text-3xl mb-5 font-bold md:px-20 text-center'>Direksi</h1>
      <div className='grid grid-cols-2 md:grid-cols-2 gap-32 md:px-20'>
        <div className='justify-self-end'>
          <Animate duration={0.5} from='bottom'>
            <Direksi imageUrl={dirut} title='Direktur Utama' name='Venpri Sagara' />
          </Animate>
        </div>
        <div className='justify-self-start'>
          <Animate duration={0.5} from='bottom'>
            <Direksi imageUrl={direktur} title='Direktur' name='M. Riza Afriansya' />
          </Animate>
        </div>
      </div>
      <h1 className='text-3xl mb-5 font-bold md:px-20 text-center'>Komisaris</h1>
      <div className='grid grid-cols-2 md:grid-cols-3 gap-8 md:px-20 justify-items-center'>
        <Animate duration={0.5} from='bottom'>
          <Direksi imageUrl={komut} title='Komisaris Utama' name='Setiadi Wicaksono' />
        </Animate>
        <Animate duration={0.5} from='bottom'>
          <Direksi imageUrl={komisaris2} title='Komisaris' name='Robert Heri' />
        </Animate>

        <div className='col-span-2 md:col-span-1'>
          <Animate duration={0.5} from='bottom'>
            <Direksi imageUrl={komisaris} title='Komisaris' name='Dwi Anggoro Ismukurnianto' />
          </Animate>
        </div>
      </div>
    </div>
  );
};

export default Jajaran;
