import React from 'react';
import Animate from './Animate';

const Header = () => {
  return (
    <div className='header bg-[#f8f8f8] px-10'>
      <div className='flex md:flex-row flex-col items-center py-4'>
        <div className='w-full md:w-1/3 p-10 md:p-20'>
          <Animate from='left' duration={0.5}>
            <h1 className='text-[#08325c] font-bold text-xl md:text-3xl md:text-start text-center'>
              Tentang
            </h1>
            <h1 className='text-[#08325c] font-bold text-xl md:text-xl md:text-start text-center'>
              Bukit Multi Investama
            </h1>
          </Animate>
        </div>
        <div className='w-full md:w-2/3 md:px-24 pb-10 md:pb-0'>
          <Animate duration={0.5}>
            <p className='text-end text-sm md:text-md'>
              {`Bukit Multi Investama (BMI) merupakan anak perusahaan PT Bukit Asam Tbk (PTBA). BMI berdiri sejak 9 September 2014, dengan eksistensi yang bertujuan untuk mengembangkan bisnis usaha di luar bisnis inti induk perusahaan.
Sampai dengan saat ini, BMI menjadi induk dari 4 (empat) anak perusahaan yaitu PT Bumi Sawindo Permai (BSP), PT Bukit Asam Medika (BAM), PT Satria Bahana Sarana (SBS) dan PT Bukit Multi Properti (BMP) serta 1 (satu) perusahaan terafiliasi PT Sinergi Mitra Lestari Indonesia (SMLI).
Melalui sinergi antara unit usahanya dengan menerapkan prinsip kehati-hatian dan tata kelola perusahaan yang baik, BMI berkomitmen penuh untuk mencapai visi misi dan memenuhi harapan pemegang saham.`}
            </p>
          </Animate>
        </div>
      </div>
    </div>
  );
};

export default Header;
