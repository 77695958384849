import React from 'react';
import Usaha from './Card/Usaha';
import bmp from '../assets/images/bmp-logo.png';
import bam from '../assets/images/bam-logo.png';
import bsp from '../assets/images/bsp-logo.png';
import sbs from '../assets/images/sbs-logo.png';
import smli from '../assets/images/sinergi-logo.png';
import Animate from './Animate';

const NewsSection = () => {
  return (
    <div className='header bg-[#f8f8f8] p-10 text-primary'>
      <h1 className='text-3xl mb-5 font-bold md:px-20 text-center md:text-start'>
        Anak Perusahaan
      </h1>
      <Animate duration={0.5} from='bottom'>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-8 md:px-20 justify-items-center'>
          <Usaha imageUrl={bmp} title='Bukit Multi Properti' />
          <Usaha imageUrl={bam} title='Bukit Asam Medika' href='https://rsbam.co.id' />
          <Usaha imageUrl={bsp} title='Bumi Sawindo Permai' />
        </div>
      </Animate>
      <Animate duration={0.5} from='bottom'>
        <div className='grid grid-cols-1 md:grid-cols-2 justify-items-center gap-8 md:px-20 my-5'>
          <Usaha imageUrl={sbs} title='Satria Bahana Sarana' href='https://satriabahana.co.id//' />
          <Usaha
            imageUrl={smli}
            title='Sinergi Mitra Lestari Indonesia'
            href='https://sinergilestari.id/'
          />
        </div>
      </Animate>
    </div>
  );
};

export default NewsSection;
