import React from 'react';
import Animate from './Animate';

const VisiMisi = () => {
  return (
    <div className='bg-image py-10 md:px-20 px-10'>
      <div className='flex flex-col md:flex-row items-center justify-start vision header '>
        <div className='flex flex-col items-center justify-center md:p-10'>
          <Animate duration={0.4} from='left'>
            <div className='relative p-5 w-full'>
              <div className='left-0 top-0 z-10 absolute bg-primary pr-5 rounded-full flex items-center h-10'>
                <div className='w-10 h-10 rounded-full bg-secondary flex items-center justify-center mr-4 shadow-secondary'>
                  <div className='w-8 h-8 rounded-full bg-primary flex items-center justify-center'>
                    <div className='w-5 h-5 bg-white rounded-full'></div>
                  </div>
                </div>
                <h1 className='text-xl text-white font-bold'>VISI</h1>
              </div>
              <div className='px-10 py-7 drop-shadow-2xl rounded-md flex  items-center bg-white shadow-md'>
                <p className='text-primary text-xl font-semibold text-center'>
                  Menjadi Perusahaan Investasi yang profitable, tumbuh, dan mandiri.
                </p>
              </div>
            </div>
          </Animate>
        </div>
      </div>
      <div className='flex flex-col md:flex-row items-center justify-start vision my-10 header'>
        <div className='flex flex-col items-center justify-center md:pr-10 md:pl-28'>
          <Animate duration={0.4}>
            <div className='relative p-5 w-full'>
              <div className='left-0 top-0 z-10 absolute bg-primary pr-5 rounded-full flex items-center h-10'>
                <div className='w-10 h-10 rounded-full bg-secondary flex items-center justify-center mr-4 shadow-secondary'>
                  <div className='w-8 h-8 rounded-full bg-primary flex items-center justify-center'>
                    <div className='w-5 h-5 bg-white rounded-full'></div>
                  </div>
                </div>
                <h1 className='text-xl text-white font-bold'>MISI</h1>
              </div>
              <div className='px-10 py-7 drop-shadow-2xl rounded-md flex  items-center bg-white shadow-md'>
                <p className='text-primary text-center text-xl font-semibold'>
                  Mengoptimalkan investasi dengan menyinergikan anak-anak perusahaan dan memberikan
                  nilai tambah yang berkelanjutan kepada seluruh pemangku kepentingan.
                </p>
              </div>
            </div>
          </Animate>
        </div>
      </div>
    </div>
  );
};

export default VisiMisi;
