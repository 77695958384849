import React, { useRef } from 'react';
import { useInView } from 'framer-motion';

export default function Animate({ children, from, duration }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  return (
    <section ref={ref}>
      <div
        style={{
          transform: isInView
            ? 'none'
            : from === 'left'
            ? 'translateX(-200px)'
            : from === 'top'
            ? 'translateY(-200px)'
            : from === 'bottom'
            ? 'translateY(200px)'
            : 'translateX(200px)',
          opacity: isInView ? 1 : 0,
          transition: `all ${duration}s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s`,
        }}
      >
        {children}
      </div>
    </section>
  );
}
