import React from 'react';

const Direksi = ({ imageUrl, title, name }) => {
  return (
    <div className='flex flex-col items-center gap-2 my-5'>
      <img
        src={imageUrl}
        alt='Foto'
        className='md:w-48 md:h-48 w-24 h-24 rounded-full object-contain border border-secondary shadow-md'
      />
      <div>
        <p className='text-center md:text-lg text-sm font-bold'>{title}</p>
        <p className='text-center md:text-base text-sm'>{name}</p>
      </div>
    </div>
  );
};

export default Direksi;
