import React from 'react';
import logo from '../assets/images/logo-white.png';
import logoColor from '../assets/images/logo-color.png';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';

const NavigationBar = ({ children }) => {
  const [navbarBackground, setNavbarBackground] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setNavbarBackground(true);
      } else {
        setNavbarBackground(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <nav
        className={`${
          navbarBackground || location.pathname === '/about'
            ? 'bg-white text-primary'
            : 'bg-transparent text-white'
        } flex px-10 py-4 items-center justify-between  sticky top-0 w-full z-20 transition-colors duration-500`}
      >
        <div>
          <a href='/'>
            <img
              src={navbarBackground || location.pathname === '/about' ? logoColor : logo}
              alt='logo'
              className='w-16'
            />
          </a>
        </div>
        <div className='flex items-center gap-4 font-semibold  text-sm'>
          <a href='/' className='hover:text-blue-800'>
            Beranda
          </a>
          <a href='/about' className='hover:text-blue-800'>
            Tentang
          </a>
          <a href='#footer' className='hover:text-blue-800'>
            Kontak
          </a>
        </div>
      </nav>
      <div className='margin-min'>{children}</div>
      <Footer />
    </div>
  );
};

export default NavigationBar;
