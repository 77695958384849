import React from 'react';
import Slider from 'react-slick';
import bmi from '../assets/images/bmi.jpg';
import bsp from '../assets/images/bsp.jpg';
import bmp from '../assets/images/bmp.jpeg';
import bam2 from '../assets/images/bam2.jpg';
import sbs from '../assets/images/sbs.jpeg';

const Images = [
  { id: 1, src: bmi },
  { id: 2, src: bsp },
  { id: 3, src: bam2 },
  { id: 4, src: sbs },
  { id: 5, src: bmp },
];

const Hero = () => {
  let settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings}>
      {Images.map((el) => (
        <div key={el.id} className='mx-auto'>
          <img
            src={el.src}
            alt=''
            className='w-full max-h-72 md:max-h-[50rem] object-cover filtered'
          />
        </div>
      ))}
    </Slider>
  );
};

export default Hero;
