import './App.css';
import Header from './Components/Header';
import Hero from './Components/Hero';
import Jajaran from './Components/Jajaran';
import NavigationBar from './Components/NavigationBar';
import NewsSection from './Components/NewsSection';
import VisiMisi from './Components/VisiMisi';

function App() {
  return (
    <NavigationBar>
      <Hero />
      <Header />
      <VisiMisi />
      <NewsSection />
      <Jajaran />
    </NavigationBar>
  );
}

export default App;
