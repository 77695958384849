import React from 'react';
import logo from '../assets/images/logo-white.png';
import { useState } from 'react';
import ModalEmail from './Modal';

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className='bg-primary text-white px-20 text-sm' id='footer'>
        <div className='max-w-7xl'>
          <div className='grid grid-cols-1 md:grid-cols-4 justify-center py-20 md:justify-items-center gap-4'>
            <div>
              <img src={logo} alt='logo' className='w-32 mb-10' />
              <p className='font-bold mb-2'>PT Bukit Multi Investama</p>
              <p className='mb-2'>
                Menara Kadin Indonesia, 9th Floor <br />
                Jl. H. R. Rasuna Said Blok X-5 Kav. 2-3 <br />
                Jakarta 12950, Indonesia
              </p>
              <p>{'Telp (+62) 21 5254014'}</p>
              <p>{'Fax (+62) 21 5254002'}</p>
            </div>
            <div className='flex flex-col gap-1'>
              <p className='font-bold mb-2'>Tentang Kami</p>
              <a href='/about'>Profil</a>
              <a href='/about'>Tentang</a>
              <p className='font-bold mb-2 mt-3'>Anak Perusahaan</p>
              <a
                href='https://satriabahana.co.id/'
                target='_blank'
                rel='noopener noreferrer'
                className='hover:text-secondary'
              >
                PT Sarana Bahana Satria
              </a>
              <a href='/' className='hover:text-secondary'>
                PT Bukit Multi Properti
              </a>
              <a
                href='https://rsbam.co.id/'
                target='_blank'
                rel='noopener noreferrer'
                className='hover:text-secondary'
              >
                PT Bukit Asam Medika
              </a>
              <a href='/' className='hover:text-secondary'>
                PT Bumi Sawindo Permai
              </a>
              <a
                href='https://sinergilestari.id/'
                target='_blank'
                rel='noopener noreferrer'
                className='hover:text-secondary'
              >
                PT Sinergi Mitra Lestari Indonesia
              </a>
            </div>
            <div>
              <p className='font-bold mb-2'>Berita</p>
              <a href='/'>Berita Terbaru</a>
            </div>
            <div>
              {' '}
              <p className='font-bold mb-2'>Hubungi Kami</p>
              {/* <button onClick={() => setIsOpen(!isOpen)}>Kirim Email</button> */}
              <a href='mailto:admptbmi@bukitmultiinvestama.co.id'>Kirim Email</a>
            </div>
          </div>
        </div>
      </div>
      <ModalEmail isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default Footer;
