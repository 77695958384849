import React from 'react';

const Usaha = ({ imageUrl, title, href }) => {
  return (
    <a
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      className='flex items-center shadow-xl md:min-h-32 min-h-32 max-w-80 rounded-lg border-2 border-grey cursor-pointer'
    >
      <img src={imageUrl} alt='foto' className='object-contain w-1/2 h-1/2' />
      <div className='md:py-5 w-1/2 px-8'>
        <h2 className='my-2 font-bold'>{title}</h2>
      </div>
    </a>
  );
};

export default Usaha;
