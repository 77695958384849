import React from 'react';
import NavigationBar from '../Components/NavigationBar';

const About = () => {
  return (
    <NavigationBar>
      <div className='pt-32'>
        <div className='md:pl-10 px-10 md:px-0'>
          <h1 className='text-2xl font-semibold mb-6 mt-2'>Tentang Bukit Multi Investama</h1>
          <div className='grid grid-cols-2 md:grid-cols-3 gap-3'>
            <div className='col-span-2 md:pr-8'>
              <p className='text-justify'>
                PT Bukit Multi Investama (BMI) merupakan anak perusahaan PT Bukit Asam Tbk (PTBA).
                BMI berdiri sejak 9 September 2014, dengan eksistensi yang bertujuan untuk
                mengembangkan bisnis usaha di luar bisnis inti induk perusahaan. Sampai dengan saat
                ini, BMI menjadi induk dari 4 (empat) anak perusahaan yaitu PT Bumi Sawindo Permai
                (BSP), PT Bukit Asam Medika (BAM), PT Satria Bahana Sarana (SBS) dan PT Bukit Multi
                Properti (BMP) serta 1 (satu) perusahaan terafiliasi PT Sinergi Mitra Lestari
                Indonesia (SMLI). <br /> <br />
                BSP diakuisisi oleh BMI pada 17 Oktober 2014 dengan fokus bidang usaha yaitu
                perkebunan kelapa sawit. BSP saat ini menguasai lahan HGU seluas 8.345,90 Ha. Pada
                23 Desember 2014, BMI mendirikan BAM dengan fokus bidang usaha yang menjalankan dan
                mengelola rumah sakit, klinik, poliklinik, klinik spesialis, dan balai pengobatan.{' '}
                <br /> <br />
                BMI melakukan akuisisi SBS Pada 25 Januari 2015, dengan fokus bidang usaha jasa
                terkait penambangan, yaitu jasa pengupasan tanah, jasa penambangan batu bara, dan
                jasa penyewaan alat berat. <br /> <br /> Dalam rangka menambah portofolio, BMI
                melakukan penyertaan saham sebanyak 25% kepada PT Panca Mitra Limbah Indonesia
                (PMLI) pada tanggal 21 November 2017, NHL berfokus di bidang usaha pengelolaan
                limbah B3 dan non B3 yang kemudian merubah nama menjadi PT Sinergi Mitra Lestari
                Indonesia (SMLI). <br /> <br /> Tanggal 26 Juni 2019, BMI mendirikan BMP yang
                berfokus di bidang usaha properti dan hospitality. Dalam rangka pengembangan bisnis,
                BMI melakukan penambahan bidang usaha selain menjalankan aktivitas perusahaan
                holding berdasarkan Akta Nomor 27 tanggal 15 Agustus 2023, antara lain:
              </p>
            </div>
            <div className='md:col-span-1 col-span-2 my-8 md:my-0'>
              <img
                src={require('../assets/images/bmi.jpg')}
                alt='bmi-logo'
                className='w-full h-[75vh] object-cover rounded-lg shadow-md'
              />
            </div>
          </div>
        </div>
        <div className='px-8 md:px-16 my-10 md:text-sm text-xs'>
          <div className='bg-primary py-2 rounded-t-md'>
            <p className='font-semibold text-white text-center'>Kegiatan Usaha</p>
          </div>
          <div className='bg-white divide-y divide-secondary flex flex-col border-b border-b-secondary'>
            <p className='p-2 text-primary'>1. Aktivitas Perusahaan Holding:</p>
            <p className='p-2 text-primary'>2. Perdagangan Besar;</p>
            <p className='p-2 text-primary'>3. Aktivitas Ketenagakerjaan;</p>
            <p className='p-2 text-primary'>4. Aktivitas Konsultasi Manajemen;</p>
            <p className='p-2 text-primary'>5. Aktivitas Kantor Pusat;</p>
            <p className='p-2 text-primary'>6. Aktivitas Hukum;</p>
            <p className='p-2 text-primary'>
              7. Aktivitas Penyewaan dan Sewa Guna Usaha Tanpa Hak Opsi Mesin, Peralatan dan barang
              Berwujud Lainnya;
            </p>
            <p className='p-2 text-primary'>
              8. Aktivitas Pemrograman, Konsultasi Komputer dan YBDI (Yang Berhubungan Dengan Itu);
            </p>
          </div>
        </div>
        <div className='px-8 mb-8'>
          <p>
            Melalui sinergi antar unit usahanya dengan menerapkan prinsip kehati-hatian dan tata
            Kelola perusahaan yang baik, BMI berkomitmen penuh untuk memenuhi harapan pemegang
            saham.
          </p>
        </div>
      </div>
    </NavigationBar>
  );
};

export default About;
